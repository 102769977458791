import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DbtRunnerService } from '../../core/dbt-runner/dbt-runner.service';
import { map, mergeMap, Observable, Subject, takeUntil, timer } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { DbtRefresh } from '@tdf/rtpca-models';

const DBTRUNNER_COMPLETED = 'Completed';
const DBTRUNNER_RUNNING = 'Running';
const DBTRUNNER_FAILED = 'Failed';
@Component({
  selector: 'rtpca-dbt-runner',
  standalone: true,
  template: `<span [ngClass]="statusClass" class="badge me-2">
    {{ 'dbtRunner.' + runtimeStatus | translate }}
  </span> `,
  styleUrls: [],
  imports: [TranslateModule, NgClass],
})
export class DbtRunnerComponent implements OnInit, OnDestroy {
  refreshApi = `${environment.baseUrl}` + '/track-refresh';
  runtimeStatus!: string | null;
  statusClass!: string;
  public  unsubscribe$ = new Subject<void>();
  public pollingInterval = 3000;
  constructor(private dbtRefresh: DbtRunnerService) {}

  ngOnInit(): void {
    this.startPolling();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private startPolling(): void {
    const dbtRefresh$ = this.dbtRefresh.getRuntimeStatus();
    this.polldbtRefresh$(dbtRefresh$).subscribe();
  }

  private polldbtRefresh$(dbtObs$: Observable<DbtRefresh[]>): Observable<void> {
    return timer(1, this.pollingInterval).pipe(
      mergeMap(() => dbtObs$),
      map(data => this.processData(data)),
      takeUntil(this.unsubscribe$)
    );
  }

  private processData(data: DbtRefresh[]): void {
    if (this.hasRunningInstances(data)) {
      this.updateRuntimeStatusToRunning(data);
    } else if (data.length > 0) {
      this.updateRuntimeStatusToLatest(data);
    } else {
      this.runtimeStatus = DBTRUNNER_COMPLETED;
    }

    this.updateStatusMessageAndColor();
  }

  private hasRunningInstances(data: DbtRefresh[]): boolean {
    return data.some(instance => instance.runtimeStatus === DBTRUNNER_RUNNING);
  }

  private updateRuntimeStatusToRunning(data: DbtRefresh[]): void {
    const runningInstance = data.find(instance => instance.runtimeStatus === DBTRUNNER_RUNNING);
    if (runningInstance) {
      this.runtimeStatus = runningInstance.runtimeStatus;
    }
  }

  private updateRuntimeStatusToLatest(data: DbtRefresh[]): void {
    const initialValue = { 'lastUpdatedTime': new Date(0).toString(), 'runtimeStatus': '' };
    const latestInstance = data.reduce((prev, current) =>
      new Date(current.lastUpdatedTime) > new Date(prev.lastUpdatedTime) ? current : prev, initialValue 
    );

    this.runtimeStatus = latestInstance ? latestInstance.runtimeStatus : null;
  }

  private updateStatusMessageAndColor(): void {
    this.statusClass = this.getStatusClass(this.runtimeStatus);
  }

  private getStatusClass(runtimeStatus: string | null): string {
    switch (runtimeStatus) {
      case DBTRUNNER_RUNNING:
        return 'badge-warning';
      case DBTRUNNER_FAILED:
        return 'badge-danger';
      case DBTRUNNER_COMPLETED:
      default:
        return 'badge-success';
    }
  }
}
