import {
  BrowserCacheLocation,
  InteractionStatus,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { LogLevel } from '@azure/msal-common';
import { MsalGuardConfiguration } from '@azure/msal-angular/msal.guard.config';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { inject, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, skipWhile } from 'rxjs';

export function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authentication.clientId,
      authority: environment.authentication.authority,
      redirectUri: environment.authentication.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      loggerOptions: {
        piiLoggingEnabled: false,
        logLevel: LogLevel.Info,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map<string, Array<string>>([
      [environment.baseUrl, environment.authentication.scopes],
    ]),
  };
}

export function provideMsal(): Provider[] {
  return [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ];
}

export const RoleGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const msalService: MsalService = inject(MsalService);
  const router: Router = inject(Router);
  const msalBroadcastService: MsalBroadcastService =
    inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      skipWhile(() => msalService.instance.getAllAccounts().length === 0)
    )
    .pipe(
      map(() => {
        const account =
          msalService.instance.getActiveAccount() ||
          msalService.instance.getAllAccounts()[0];
        const userRoles = account.idTokenClaims?.roles;
        const refinery = userRoles
          ?.find((role) => role.startsWith('ref_'))
          ?.replace('ref_', '');
        if (refinery) {
          return true;
        } else {
          router.navigate(['/unauthorized']);
          return false;
        }
      })
    );
};