import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'extractRole',
})
export class ExtractRolePipe implements PipeTransform {
  private refineryName!: string;
  transform(roles: string[]): string {
    const role = roles.find((role) => role.includes('ref_'));
    if (role) {
      this.refineryName = role.replace('ref_', '');
    }
    return this.refineryName;
  }
}
