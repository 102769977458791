import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DbtRefresh } from '@tdf/rtpca-models';

@Injectable({
  providedIn: 'root',
})
export class DbtRunnerService {
  private refreshApi = `${environment.baseUrl}` + '/track-refresh';
  constructor(private http: HttpClient) {}

  getRuntimeStatus() {
    return this.http.get<DbtRefresh[]>(this.refreshApi);
  }

}
